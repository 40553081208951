import { useQuery, useLazyQuery } from '@apollo/client'

import { useGetCompanies } from '../Cms/hooks'
import * as QUERIES from './queries'

export function useGetUsageStats(range) {
  const { loading, error, data } = useQuery(QUERIES.GET_USAGE_STATS, {
    variables: range,
  })

  return { loading, error, data }
}

export function useGetUsage() {
  const [usage, { loading, error, data }] = useLazyQuery(QUERIES.GET_USAGE)
  const getUsage = range => usage({ variables: range })

  return [getUsage, { loading, error, data }]
}

export const useGetAllCompanies = useGetCompanies
