import * as Yup from 'yup'
import { ACCESS_ROLES } from './constants'

export const signUpSchema = Yup.object({
  firstName: Yup.string().required('First Name is required!'),
  lastName: Yup.string(),

  email: Yup.string().email('Invalid Email!').required('Email is required!'),
  password: Yup.string()
    .required('Password is required!')
    .min(8, 'Password is too short - should be 8 characters minimum.'),
  passwordConfirm: Yup.string()
    .required('Confirm Password is required!')
    .oneOf([Yup.ref('password'), null], 'Passwords must match!'),

  companyName: Yup.string().required('Company Name is required!'),
  companyWebsite: Yup.string()
    .required('Company Website is required!')
    .url('Invalid website'),

  accessRole: Yup.string()
    .required('Access role is required!')
    .oneOf([...ACCESS_ROLES.map(({ value }) => value)])
    .typeError('Select a valid value!'),
})
