import React from 'react'
import { Redirect } from 'react-router-dom'

import { useGetMe } from './hooks'
import { SIGN_IN } from '../../constants/routes'

export const withAuthorization = conditionFn => Component => props => {
  const { data, networkStatus } = useGetMe()

  if (networkStatus < 7) return null

  return (
    <>
      {conditionFn(data) ? (
        <Component {...props} />
      ) : (
        <Redirect to={SIGN_IN} />
      )}
    </>
  )
}

const withSession = Component => props => {
  const { loading, data, refetch } = useGetMe()

  return (
    <Component session={data} refetch={refetch} loading={loading} {...props} />
  )
}

export default withSession
