import { gql } from '@apollo/client'

export const GET_NEW_TOKEN = gql`
  mutation getTokens($refreshToken: String) {
    tokens(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`
