import { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import ToastNotification from '../../../../Notification'
import { usePublishSite } from './hooks'

function Publish(props) {
  const {
    refetch,
    site: {
      id,
      isPublished,
      iso,
      landowners,
      leaseRate,
      leaseEsc,
      offtakerDemandInMWh,
      nOfftakers,
    },
  } = props

  const [publishSite, { loading }] = usePublishSite(refetch)
  const [showToast, setShowToast] = useState(false)
  const publishable =
    !!landowners?.anchor?.length &&
    !!landowners?.all?.length &&
    iso &&
    leaseRate &&
    !(!leaseEsc && leaseEsc !== 0) &&
    offtakerDemandInMWh &&
    nOfftakers

  return (
    <>
      <ToastNotification
        show={showToast}
        onSuspend={() => setShowToast(false)}
        headerText="ERROR"
        bodyText={
          <>
            <p className="m-0 p-0">
              Oh snap! Something went wrong. Please try again.
            </p>
            <p className="m-0 p-0">
              <b>PS:</b> Have you tried turning it off and on again?
            </p>
          </>
        }
        type="danger"
      />
      <Button
        variant={isPublished ? 'outline-danger' : 'outline-primary'}
        size="sm"
        onClick={() => publishSite(id, !isPublished)}
        disabled={(loading || !publishable) && !isPublished}
        className="d-flex justify-content-center align-items-center"
      >
        {loading && (
          <Spinner
            animation="grow"
            size="sm"
            variant="primary"
            className="me-1"
          />
        )}
        <span>
          {isPublished ? 'Unpublish' : publishable ? 'Publish' : 'Incomplete'}
        </span>
      </Button>
    </>
  )
}

export default Publish
