import { gql } from '@apollo/client'

export const SIGNUP = gql`
  mutation addUser($user: UserInput!) {
    response: addUserAdmin(user: $user) {
      success
      message
    }
  }
`
