import React, { useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'

function ParcelEdit(props) {
  const { parcel, onFormSubmit } = props
  const [validated, setValidated] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      setButtonDisabled(true)
      onFormSubmit({
        ownerName: event.target.ownerName.value,
        ownerEmail: event.target.ownerEmail.value,
        ownerPhone: event.target.ownerPhone.value,
        parcelAcreage: event.target.parcelAcreage.value,
        parcelNumber: event.target.parcelNumber.value,
        iso: event.target.iso.value,
        leaseRate: event.target.leaseRate.value,
        leaseEsc: event.target.leaseEsc.value,
        isEnergyCommunityZone: event.target.isEnergyCommunityZone.checked,
        offtakerDemandInMWh: event.target.offtakerDemandInMWh.value,
        nOfftakers: event.target.nOfftakers.value,
        id: parcel.id,
      })
    }
    setValidated(true)
  }
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="ownerName">
        <Form.Label>Owner Name</Form.Label>
        <Form.Control defaultValue={parcel.ownerName} required />
        <Form.Control.Feedback type="invalid">
          Owner Name is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="ownerEmail">
        <Form.Label>Owner Email</Form.Label>
        <Form.Control type="email" defaultValue={parcel.ownerEmail} required />
        <Form.Control.Feedback type="invalid">
          Owner Email is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="ownerPhone">
        <Form.Label>Owner Phone</Form.Label>
        <Form.Control defaultValue={parcel.ownerPhone} required />
        <Form.Control.Feedback type="invalid">
          Owner Phone is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="parcelNumber">
        <Form.Label>Parcel Number</Form.Label>
        <Form.Control
          type="number"
          defaultValue={parcel.parcelNumber}
          required
        />
        <Form.Control.Feedback type="invalid">
          Parcel Number is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="parcelAcreage">
        <Form.Label>Parcel Acreage</Form.Label>
        <Form.Control
          type="number"
          defaultValue={parcel.parcelAcreage}
          required
        />
        <Form.Control.Feedback type="invalid">
          Parcel Acreage is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="leaseRate">
        <Form.Label>Per Acre Lease Rate ($)</Form.Label>
        <Form.Control defaultValue={parcel.leaseRate} type="number" required />
        <Form.Control.Feedback type="invalid">
          Lease Rate is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="nOfftakers">
        <Form.Label>Potential Offtakers</Form.Label>
        <Form.Control defaultValue={parcel.nOfftakers} type="number" required />
        <Form.Control.Feedback type="invalid">
          Number of offtakers is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="offtakerDemandInMWh">
        <Form.Label>Offtaker Demand (MWh)</Form.Label>
        <Form.Control
          defaultValue={parcel.offtakerDemandInMWh}
          type="number"
          required
        />
        <Form.Control.Feedback type="invalid">
          Offtaker Demand is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="iso">
        <Form.Label>ISO region</Form.Label>
        <Form.Control defaultValue={parcel.iso} as="select" custom>
          <option value="PJM">PJM</option>
          <option value="MISO">MISO</option>
          <option value="ERCOT">ERCOT</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          ISO is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="leaseEsc">
        <Form.Label>Lease Escalation ($/year)</Form.Label>
        <Form.Control defaultValue={parcel.leaseEsc} type="number" required />
        <Form.Control.Feedback type="invalid">
          Lease Escalation is required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="isEnergyCommunityZone">
        <Form.Check
          defaultChecked={parcel.isEnergyCommunityZone}
          className="d-flex align-items-center"
          type="checkbox"
          label="Energy Community Zone?"
        />
      </Form.Group>
      <Button disabled={buttonDisabled} variant="primary" type="submit">
        <div className="d-flex align-items-center justify-content-center">
          {buttonDisabled && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"
            />
          )}
          <span>Save</span>
        </div>
      </Button>
    </Form>
  )
}

export default ParcelEdit
