import React from 'react'
import Select from 'react-select'
import { Col, Row } from 'react-bootstrap'
import { withAuthorization } from '../Session'
import { Card, Text, Subtitle } from '../Themed'
import { DROPDOWN_STYLES } from '../../constants/styles'
import { ForwardCurveForm } from './forms'
import { useFetchForwardCurve } from './hooks'

function ForwardCurve(props) {
  const [fetchFwdCurve, { loading }] = useFetchForwardCurve()

  const handleFwdCurveFetch = values => {
    const { frequency, clearingCode } = values

    fetchFwdCurve(clearingCode, frequency)
  }

  return (
    <ForwardCurveForm onFormSubmit={handleFwdCurveFetch} loading={loading} />
  )
}

function MarketData(props) {
  return (
    <Card padding={32}>
      <Row>
        <Col>
          <Subtitle className="mb-2">Forward Curve</Subtitle>
          <ForwardCurve />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </Card>
  )
}

export default withAuthorization(session => session && session.me)(MarketData)
