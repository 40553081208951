import { useQuery, useMutation } from '@apollo/client'
import * as QUERIES from './queries'
import * as MUTATIONS from './mutations'

export function useGetEmailDomains() {
  const { loading, error, data } = useQuery(QUERIES.GET_EMAIL_DOMAINS)

  return { loading, error, data }
}

export function useCreateEmailDomain() {
  const [createEmailDomain, { loading, error, data }] = useMutation(
    MUTATIONS.CREATE_EMAIL_DOMAIN,
    {
      refetchQueries: () => [
        {
          query: QUERIES.GET_EMAIL_DOMAINS,
        },
      ],
    }
  )
  const createDomain = domain => createEmailDomain({ variables: { domain } })

  return [createDomain, { loading, error, data }]
}

export function useUpdateEmailDomain() {
  const [updateEmailDomain, { loading, error, data }] = useMutation(
    MUTATIONS.UPDATE_EMAIL_DOMAIN,
    {
      refetchQueries: () => [
        {
          query: QUERIES.GET_EMAIL_DOMAINS,
        },
      ],
    }
  )
  const updateDomain = (id, domain) =>
    updateEmailDomain({ variables: { id, domain } })

  return [updateDomain, { loading, error, data }]
}

export function useDeleteEmailDomain() {
  const [deleteEmailDomain, { loading, error, data }] = useMutation(
    MUTATIONS.DELETE_EMAIL_DOMAIN,
    {
      refetchQueries: () => [
        {
          query: QUERIES.GET_EMAIL_DOMAINS,
        },
      ],
    }
  )
  const deleteDomain = id => deleteEmailDomain({ variables: { id } })

  return [deleteDomain, { loading, error, data }]
}

export function useGetConfigurations() {
  const { loading, error, data } = useQuery(QUERIES.GET_CONFIGURATIONS)

  return { loading, error, data }
}

export function useCreateConfiguration() {
  const [createConfiguration, { loading, error, data }] = useMutation(
    MUTATIONS.CREATE_CONFIGURATION,
    {
      refetchQueries: () => [
        {
          query: QUERIES.GET_CONFIGURATIONS,
        },
      ],
    }
  )
  const createConfig = config => createConfiguration({ variables: { config } })

  return [createConfig, { loading, error, data }]
}

export function useUpdateConfiguration() {
  const [updateConfiguration, { loading, error, data }] = useMutation(
    MUTATIONS.UPDATE_CONFIGURATION,
    {
      refetchQueries: () => [
        {
          query: QUERIES.GET_CONFIGURATIONS,
        },
      ],
    }
  )
  const updateConfig = (id, config) =>
    updateConfiguration({ variables: { id, config } })

  return [updateConfig, { loading, error, data }]
}

export function useDeleteConfiguration() {
  const [deleteConfiguration, { loading, error, data }] = useMutation(
    MUTATIONS.DELETE_CONFIGURATION,
    {
      refetchQueries: () => [
        {
          query: QUERIES.GET_CONFIGURATIONS,
        },
      ],
    }
  )
  const deleteConfig = id => deleteConfiguration({ variables: { id } })

  return [deleteConfig, { loading, error, data }]
}
