import React from 'react'
import { ApolloConsumer } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { SIGN_IN } from '../../constants/routes'

function SignOut(props) {
  const history = useHistory()

  const onSignOut = client => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    client.resetStore()
    history.push(SIGN_IN)
  }

  return (
    <ApolloConsumer>
      {client => (
        <span
          className="cursor-pointer material-icons text-danger btn"
          onClick={() => onSignOut(client)}
        >
          power_settings_new
        </span>
      )}
    </ApolloConsumer>
  )
}

export default SignOut
