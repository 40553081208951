const GRAPHIC_COLOR_BASE = [51, 204, 255] // blue-ish
const GRAPHIC_COLOR_WITH_TRANSPARENCY = [...GRAPHIC_COLOR_BASE, 0.1]

export const GRAPHIC_PROPERTIES = {
  symbol: {
    type: 'simple-fill',
    color: GRAPHIC_COLOR_WITH_TRANSPARENCY,
    outline: {
      color: GRAPHIC_COLOR_BASE,
      width: 1,
    },
  },
  popupTemplate: {
    title: '{number}',
    content: [
      {
        type: 'fields',
        fieldInfos: [
          { fieldName: 'number', label: 'Parcel Number' },
          {
            fieldName: 'streetAddress',
            label: 'Street',
          },
          { fieldName: 'city', label: 'City' },
          { fieldName: 'state', label: 'State' },
          { fieldName: 'postalCode', label: 'Postal Code' },
          { fieldName: 'acres', label: 'Acres' },
          { fieldName: 'ownerName', label: 'Owner' },
          { fieldName: 'ownerEmail', label: 'Owner Email' },
          { fieldName: 'ownerPhone', label: 'Owner Phone' },
        ],
      },
    ],
  },
}

export const FEATURE_LAYER_PROPERTIES = {
  visible: false,
  fields: [
    {
      name: 'ObjectID',
      alias: 'ObjectID',
      type: 'oid',
    },
    {
      name: 'type',
      alias: 'Type',
      type: 'string',
    },
    {
      name: 'place',
      alias: 'Place',
      type: 'string',
    },
  ],
  source: [],
  supportsAdd: true,
  supportsEdit: true,
  geometryType: 'point',
  featureReduction: {
    type: 'cluster',
    clusterRadius: '100px',
    popupTemplate: {
      title: 'Cluster summary',
      content: 'This cluster represents {cluster_count} pre-qualified parcels.',
      fieldInfos: [
        {
          fieldName: 'cluster_count',
          format: {
            places: 0,
            digitSeparator: true,
          },
        },
      ],
    },
    clusterMinSize: '24px',
    clusterMaxSize: '60px',
    labelingInfo: [
      {
        deconflictionStrategy: 'none',
        labelExpressionInfo: {
          expression: "Text($feature.cluster_count, '#,###')",
        },
        symbol: {
          type: 'text',
          color: '#004a5d',
          font: {
            weight: 'bold',
            family: 'Noto Sans',
            size: '12px',
          },
        },
        labelPlacement: 'center-center',
      },
    ],
  },
}
