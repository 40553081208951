import styled from 'styled-components'

import * as COLORS from '../../constants/colors'

export const Card = styled.div`
  border-radius: 8px;
  background: ${props => props.background || COLORS.WHITE};
  padding: ${props => props.padding || 0}px;
  height: calc(100vh - 180px);
  position: relative;
`

export const BasicCard = styled.div`
  border-radius: 8px;
  background: ${props => props.background || COLORS.WHITE};
  padding: ${props => props.padding || 16}px;
  position: relative;
`

export const CardContent = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding || 0}px;
  height: 100%;
  ${({ offset }) => offset && `height: calc(100vh - ${offset}px)`}
`
