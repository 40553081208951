import React from 'react'
import ReactDOM from 'react-dom'
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  fromPromise,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import history from './constants/history'
import { GET_NEW_TOKEN } from './components/Session/mutations'
import App from './components/App'

const signOut = client => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  client.resetStore()
  history.push('/signin')
}

const removeTypenameLink = removeTypenameFromVariables()

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_BACKEND_SERVER}/graphql`,
  headers: {
    'apollo-require-preflight': true,
  },
})

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token')
    const rToken = localStorage.getItem('refreshToken')

    if (token) {
      headers = { ...headers, 'x-token': token }
    }

    if (rToken) {
      headers = { ...headers, 'r-token': rToken }
    }

    return { headers }
  })

  return forward(operation)
})

const getNewToken = () => {
  const refreshToken = localStorage.getItem('refreshToken')
  return client
    .mutate({
      mutation: GET_NEW_TOKEN,
      variables: { refreshToken },
    })
    .then(response => {
      const {
        data: { tokens },
      } = response

      return tokens
    })
}

let isRefreshing = false
let pendingRequests = []

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.message) {
          case 'UNAUTHENTICATED':
            let _forward

            if (!isRefreshing) {
              isRefreshing = true
              _forward = fromPromise(
                getNewToken()
                  .then(({ accessToken, refreshToken }) => {
                    localStorage.setItem('token', accessToken)
                    localStorage.setItem('refreshToken', refreshToken)
                    resolvePendingRequests()
                    return { accessToken, refreshToken }
                  })
                  .catch(error => {
                    pendingRequests = []

                    localStorage.removeItem('token')
                    localStorage.removeItem('refreshToken')

                    history.push('/signin')
                    history.go(0)

                    return
                  })
                  .finally(() => {
                    isRefreshing = false
                  })
              )
            } else {
              _forward = fromPromise(
                new Promise(resolve => pendingRequests.push(() => resolve()))
              )
            }

            return _forward.flatMap(() => forward(operation))
          default:
            return forward(operation)
        }
      }
    }

    if (networkError) {
      console.warn(`[Network error]: ${networkError}`)
      if (networkError.statusCode === 401) {
        signOut(client)
      }
    }
  }
)

export const client = new ApolloClient({
  name: 'admin-web-client',
  uri: `${process.env.REACT_APP_BACKEND_SERVER}/graphql`,
  cache: new InMemoryCache(),
  link: ApolloLink.from([removeTypenameLink, errorLink, authLink, httpLink]),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
)
