export const ERROR_TEXTS = {
  USER_EXISTS: 'The user already exists. Please use a different email address.',
  MANAGER_EXISTS:
    'The user already exists. Please use a different email address or name.',
  COMPANY_EXISTS:
    'The company already exists. Please use a different company name.',
}

export const ACCESS_ROLES = [
  { label: 'Energy Developer', value: 'DEVELOPER' },
  { label: 'Engineering', value: 'ENGINEER' },
]
