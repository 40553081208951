import React from 'react'
import { Button } from 'react-bootstrap'

import { Card, Subtitle, Title } from '../Themed'
import * as COLORS from '../../constants/colors'

function NotFound(props) {
  const { subtitle, onButtonClick, buttonText } = props

  return (
    <div className="d-flex flex-column align-items-center justify-content-center p-4">
      <img
        src="/img/noprojects.svg"
        alt="no_data"
        style={{ height: '240px' }}
        className="mb-4"
      />
      <Subtitle className="mb-4">{subtitle}</Subtitle>
      {buttonText && (
        <Button variant="primary" onClick={() => onButtonClick()}>
          {buttonText}
        </Button>
      )}
    </div>
  )
}

export function Unauthorised(props) {
  const { subtitle, onButtonClick, buttonText } = props

  return (
    <Card padding={32}>
      <div className="d-flex flex-column align-items-center justify-content-center p-4 h-100">
        <img
          src="/img/unauthorised.svg"
          alt="no_data"
          style={{ height: '240px' }}
          className="mb-4"
        />
        <Subtitle className="mb-4">{subtitle}</Subtitle>
        {buttonText && (
          <Button variant="primary" onClick={() => onButtonClick()}>
            {buttonText}
          </Button>
        )}
      </div>
    </Card>
  )
}

export function ServerError(props) {
  const { subtitle, onButtonClick, buttonText, title } = props

  return (
    <Card padding={32}>
      <div className="d-flex flex-column align-items-center justify-content-center p-4 h-100">
        <img
          src="/img/error2.svg"
          alt="no_data"
          style={{ height: '240px' }}
          className="mb-4"
        />
        {title && (
          <Title size={48} color={COLORS.PRIMARY_ALT} className="mb-4">
            {title}
          </Title>
        )}
        <Subtitle className="mb-4 text-center">{subtitle}</Subtitle>
        {buttonText && (
          <Button onClick={() => onButtonClick()}>{buttonText}</Button>
        )}
      </div>
    </Card>
  )
}

export default NotFound
