import { useQuery, useMutation } from '@apollo/client'

import * as QUERIES from './queries'
import * as MUTATIONS from './mutations'

export function useGetDataAreas(source) {
  const { loading, error, data, refetch } = useQuery(QUERIES.GET_DATA_AREAS, {
    variables: { source },
  })

  return { loading, error, data, refetch }
}

export function useUpdateAnalytics(source) {
  const [update, { loading, error, data }] = useMutation(
    MUTATIONS.UPDATE_ANALYTICS
  )

  const updateAnalytics = payload => update({ variables: { source, payload } })

  return [updateAnalytics, { loading, error, data }]
}
