import { gql } from '@apollo/client'

export const UPDATE_COMPANY_SETTINGS = gql`
  mutation updateSettings($id: ID!, $company: CompanyInput!) {
    updateCompany(id: $id, company: $company) {
      id
    }
  }
`

export const VALIDATE_SRC_API_KEY = gql`
  mutation VALIDATE_SRC_API_KEY($apiKey: String!) {
    validateSrcAPiKey(apiKey: $apiKey) {
      isValid
    }
  }
`
