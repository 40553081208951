import { useMutation } from '@apollo/client'
import { UPDATE_PARCEL } from './mutations'

export function useUpdateParcel() {
  const [updateParcel, { loading, error, data }] = useMutation(UPDATE_PARCEL)

  const updateParcelDetails = parcel => updateParcel({ variables: { parcel } })

  return [updateParcelDetails, { loading, error, data }]
}
