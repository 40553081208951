import { useQuery } from '@apollo/client'

import * as QUERIES from './queries'

export function useGetMe() {
  const { loading, error, data, networkStatus, refetch } = useQuery(
    QUERIES.GET_ME,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    }
  )

  return { loading, error, data, networkStatus, refetch }
}
