import React from 'react'
import { useHistory } from 'react-router-dom'

import { AppBar, BrandLogo, Parent, Logo, Text } from '../Themed'
import SignOut from '../Logout'

function Header(props) {
  const { session } = props
  const history = useHistory()

  if (!session?.me) return <span />

  return (
    <AppBar>
      <BrandLogo className="ml-4" onClick={() => history.push('/')}>
        <Logo src={process.env.PUBLIC_URL + '/logo512.png'} />
        <Parent>
          from <b>HST</b>
        </Parent>
      </BrandLogo>
      <div className="d-flex w-100 justify-content-between pr-4 align-items-center">
        <Text>ADMIN PANEL</Text>
        <SignOut />
      </div>
    </AppBar>
  )
}

export default Header
