import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers'

import { FormGroupContainer, Title } from '../Themed'

import { ADD_PM, ADDING_PM, FIRST_NAME, LAST_NAME, EMAIL } from './constants'
import { projectManagersSchema } from './validations'

export function ProjectManagerForm(props) {
  const { onFormSubmit, values, formRef, loading, resetForm, disabled } = props

  const { register, handleSubmit, errors, reset } = useForm({
    reValidateMode: 'onChange',
    defaultValues: values || {},
    resolver: yupResolver(projectManagersSchema),
  })

  useEffect(() => {
    if (resetForm) reset()
  }, [resetForm])

  return (
    <Form ref={formRef} noValidate onSubmit={handleSubmit(onFormSubmit)}>
      <FormGroupContainer>
        <Title className="mb-2" uppercase>
          {ADD_PM}
        </Title>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="firstName">
              <Form.Label>{FIRST_NAME}</Form.Label>
              <Form.Control
                ref={register}
                name="firstName"
                isInvalid={!!errors.firstName}
                disabled={disabled}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId="lastName">
              <Form.Label>{LAST_NAME}</Form.Label>
              <Form.Control
                ref={register}
                name="lastName"
                isInvalid={!!errors.lastName}
                disabled={disabled}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="email">
              <Form.Label>{EMAIL}</Form.Label>
              <Form.Control
                ref={register}
                name="email"
                isInvalid={!!errors.email}
                disabled={disabled}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Button
              disabled={loading || disabled}
              type="submit"
              className="mt-2"
              variant="outline-primary"
            >
              <div className="d-flex align-items-center justify-content-center">
                {loading && (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2"
                  />
                )}
                <span>{loading ? ADDING_PM : ADD_PM}</span>
              </div>
            </Button>
          </Col>
        </Row>
      </FormGroupContainer>
    </Form>
  )
}
