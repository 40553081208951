import { useState, useRef } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import EditForm from './EditForm'
import { useUpdateSiteAgreement } from './hooks'

function EditSite(props) {
  const { siteAgreement, refetch } = props
  const [show, setShow] = useState(false)
  const formRef = useRef(null)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const onCompleted = () => {
    refetch()
    handleClose()
  }

  //TODO: Error handling - Show an error popup in case an error occurs in this
  //operation.
  const [updateSiteAgreement, { loading }] = useUpdateSiteAgreement(onCompleted)

  const handleFormSubmit = (
    ({ id }) =>
    data =>
      updateSiteAgreement(id, { ...data })
  )(siteAgreement)

  const handleSave = (
    ref => () =>
      ref.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
  )(formRef)

  return (
    <>
      <Button variant="link" size="sm" onClick={handleShow}>
        Edit
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Site Agreement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditForm
            formRef={formRef}
            onFormSubmit={handleFormSubmit}
            values={{
              siteMetadata: {
                exclusivityExpiry: siteAgreement.siteMetadata.exclusivityExpiry,
              },
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button disabled={loading} variant="primary" onClick={handleSave}>
            {loading && (
              <Spinner
                className="mr-2"
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}

            <span>Save Changes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditSite
