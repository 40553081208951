import * as Yup from 'yup'

export const fwdCurveSchema = Yup.object({
  frequency: Yup.string()
    .required('Frequency is required!')
    .typeError('Enter a valid value!'),
  clearingCode: Yup.string()
    .required('Clearing Code is required!')
    .typeError('Enter a valid value!'),
})
