import { useMutation } from '@apollo/client'

import * as MUTATIONS from './mutations'

export function useAddUser() {
  const [addUserAdmin, { loading, error, data }] = useMutation(
    MUTATIONS.SIGNUP,
    {
      errorPolicy: 'all',
    }
  )

  const addUser = user => addUserAdmin({ variables: { user } })

  return [addUser, { loading, error, data }]
}
