import { gql } from '@apollo/client'

export const GET_COMPANY_ADMINS = gql`
  query {
    admins
  }
`

export const GET_PROJECT_MANAGERS = gql`
  query getProjectManagers($company: ID!) {
    managers(company: $company) {
      id
      firstName
      lastName
      email
    }
  }
`
