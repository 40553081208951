import { useMutation } from '@apollo/client'

import * as MUTATIONS from './mutations'

export function useSignIn(handleNetworkErrors, handleResponse) {
  const [signIn, { loading, error, data }] = useMutation(MUTATIONS.SIGNIN, {
    onCompleted: data => handleResponse(data),
    onError: error => handleNetworkErrors(error),
  })

  const signInUser = ({ email, password }) =>
    signIn({ variables: { email, password } })

  return [signInUser, { loading, error, data }]
}
