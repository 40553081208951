import { gql } from '@apollo/client'

export const FIND_EQUIPMENTS = gql`
  query findEquipments($filter: JSON!) {
    modules: findModulesAdmin(filter: $filter) {
      id
      name
    }
    inverters: findInvertersAdmin(filter: $filter) {
      id
      name
    }
    racks: findRacksAdmin(filter: $filter) {
      id
      name
    }
  }
`
