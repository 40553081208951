import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Col, Dropdown } from 'react-bootstrap'

import { PRIMARY } from '../../constants/colors'
import { Text } from '../Themed'

import {
  Panel,
  SideTab,
  SidePanelContainer,
  ReportPanel,
  ReportTab,
} from './styles'

function SidePanel(props) {
  const { items, session } = props

  const location = useLocation()

  if (!session?.me) return <span />

  return (
    <Panel>
      <SidePanelContainer>
        {items.map((item, index) => (
          <OverlayTrigger
            key={item.label}
            placement="right"
            overlay={<Tooltip>{item.label}</Tooltip>}
          >
            <SideTab
              active={location.pathname === item.route ? 1 : 0}
              key={item.label}
              to={item.route}
            >
              <span className="material-icons">{item.icon}</span>
            </SideTab>
          </OverlayTrigger>
        ))}
      </SidePanelContainer>
    </Panel>
  )
}

export function TopPanel(props) {
  const { tabs, onTabPress, defaultValue } = props
  const [selectedTab, setTab] = useState(defaultValue)

  const handleTabPress = (index, subIndex) => {
    setTab(index)
    onTabPress(index, subIndex)
  }

  return (
    <ReportPanel>
      {Object.keys(tabs).map((tab, index) =>
        tabs[tab].items ? (
          <Col key={tabs[tab].label}>
            <Dropdown>
              <Dropdown.Toggle
                as={ReportTab}
                active={index === selectedTab ? 1 : 0}
              >
                <Text>{tabs[tab].label}</Text>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ minWidth: 200 }}>
                {tabs[tab].items.map((item, i) => (
                  <Dropdown.Item
                    onClick={() => handleTabPress(tab, i)}
                    key={item.label}
                  >
                    <Text>{item.label}</Text>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        ) : (
          <ReportTab
            active={tab === selectedTab ? 1 : 0}
            key={tabs[tab].label}
            onClick={() => handleTabPress(tab)}
          >
            <span
              className={`material-icons mr-1 ${
                tab === selectedTab ? 'icon-primary' : 'icon-grey'
              }`}
            >
              {tabs[tab].icon}
            </span>
            <Text color={index === selectedTab ? PRIMARY : ''}>
              {tabs[tab].label}
            </Text>
          </ReportTab>
        )
      )}
    </ReportPanel>
  )
}

export default SidePanel
