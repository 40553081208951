import { gql } from '@apollo/client'

export const GET_EMAIL_DOMAINS = gql`
  query {
    domains {
      id
      organization
      domain
      status
    }
  }
`

export const GET_CONFIGURATIONS = gql`
  query {
    configurations {
      id
      type
      config
    }
  }
`
