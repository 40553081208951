import React, { useState, useEffect } from 'react'
import { ButtonGroup } from 'react-bootstrap'

import * as Styled from './styles.js'

function Switcher(props) {
  const { switchItems, defaultValue, onSwitch, block, disabled } = props
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <ButtonGroup toggle className={block ? 'w-100' : ''}>
      {switchItems.map(item => (
        <Styled.SwitchButton
          key={item.value}
          type="radio"
          disabled={disabled}
          variant={item.value === value ? 'primary' : 'light'}
          name={item.value}
          value={item.value}
          checked={item.value === value}
          onChange={e => {
            setValue(e.currentTarget.value)
            onSwitch(e.currentTarget.value)
          }}
        >
          {item.label}
        </Styled.SwitchButton>
      ))}
    </ButtonGroup>
  )
}

export default Switcher
