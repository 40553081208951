import { useMutation } from '@apollo/client'

import * as MUTATIONS from './mutations'
import * as QUERIES from '../Cms/queries'
import { useGetCompanies } from '../Cms/hooks'

export function useUpdateCompanySettings() {
  const [updateSettings, { loading, error, data }] = useMutation(
    MUTATIONS.UPDATE_COMPANY_SETTINGS,
    {
      refetchQueries: () => {
        return [
          {
            query: QUERIES.GET_COMPANIES,
          },
        ]
      },
    }
  )

  const updateCompanySettings = (id, company) =>
    updateSettings({ variables: { id, company } })

  return [updateCompanySettings, { loading, error, data }]
}

export function useValidateApiKey() {
  const [validateApiKey, { loading, error, data }] = useMutation(
    MUTATIONS.VALIDATE_SRC_API_KEY
  )
  const validate = apiKey => validateApiKey({ variables: { apiKey } })
  return [validate, { loading, error, data }]
}

export const useGetAllCompanies = useGetCompanies
