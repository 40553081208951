import { gql } from '@apollo/client'

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $user: UserInput!) {
    updateUserAdmin(id: $id, user: $user) {
      id
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!, $company: ID!) {
    deleteUserAdmin(id: $id, company: $company) {
      success
    }
  }
`

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      success
    }
  }
`
