import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers'
import Switcher from '../Switcher'

import { signUpSchema } from './validations'
import { ACCESS_ROLES, ERROR_TEXTS } from './constants'

export function SignupForm(props) {
  const { onSignUp, error, loading } = props
  const { register, handleSubmit, formState, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(signUpSchema),
  })

  const { errors } = formState

  const onSubmit = data => {
    const {
      firstName,
      lastName,
      passwordConfirm,
      companyName,
      companyWebsite,
      ...rest
    } = data
    onSignUp({
      firstName: firstName.trim(),
      lastName: lastName?.trim(),
      company: {
        name: companyName.trim(),
        website: companyWebsite.trim(),
      },
      ...rest,
    })
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <Row>
            <Col xs={6}>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  size="lg"
                  ref={register}
                  name="firstName"
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  size="lg"
                  ref={register}
                  name="lastName"
                  isInvalid={!!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              size="lg"
              ref={register}
              name="email"
              isInvalid={!!errors.email || error === 'USER_EXISTS'}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message || ERROR_TEXTS[error]}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              size="lg"
              ref={register}
              name="password"
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="passwordConfirm">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              size="lg"
              ref={register}
              name="passwordConfirm"
              isInvalid={!!errors.passwordConfirm}
            />
            <Form.Control.Feedback type="invalid">
              {errors.passwordConfirm?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="companyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              size="lg"
              ref={register}
              name="companyName"
              isInvalid={!!errors.companyName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.companyName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="companyWebsite">
            <Form.Label>Company Website</Form.Label>
            <Form.Control
              size="lg"
              ref={register}
              name="companyWebsite"
              isInvalid={!!errors.companyWebsite}
              placeholder="https://"
            />
            <Form.Control.Feedback type="invalid">
              {errors.companyWebsite?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="accessRole">
            <Form.Label>Access Role</Form.Label>
            <Controller
              render={({ onChange, value }) => (
                <Switcher
                  block
                  defaultValue={value}
                  switchItems={ACCESS_ROLES}
                  onSwitch={onChange}
                />
              )}
              name="accessRole"
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors.accessRole?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Button variant="primary" type="submit" size="lg" disabled={loading}>
        <div className="d-flex align-items-center justify-content-center">
          {loading && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"
            />
          )}
          <span>{loading ? 'Adding User' : 'Add User'}</span>
        </div>
      </Button>
    </Form>
  )
}
