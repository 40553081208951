import styled from 'styled-components'

import * as COLORS from '../../constants/colors'

export const Title = styled.h3`
  color: ${props => props.color || COLORS.GREY};

  ${({ uppercase }) =>
    uppercase &&
    `
    text-transform: uppercase;
  `}

  ${({ gradient }) =>
    gradient &&
    `
    background: -webkit-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(165, 224, 255, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}

  ${({ center }) =>
    center &&
    `
    text-align: center;
  `}

  ${({ noUnderline }) =>
    noUnderline &&
    `
    border-bottom: none;
    right: 0;
  `}
`

export const Subtitle = styled.h4`
  color: ${props => props.color || COLORS.PRIMARY_ALT};
  margin: ${props => props.margin || 0}px;
`

export const Text = styled.p`
  color: ${props => props.color || COLORS.GREY};
  margin: 0;
  font-weight: ${props => props.weight || 'normal'};
  font-size: ${props => `${props.size}px` || '0.9rem'};
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
  font-weight: ${props => props.weight || 'normal'};
  ${({ uppercase }) =>
    uppercase &&
    `
    text-transform: uppercase;
  `}
  ${({ gradient }) =>
    gradient &&
    `
    background: -webkit-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(165, 224, 255, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}
  display: -webkit-box;
  -webkit-line-clamp: ${props =>
    props.lines || 1}; /* number of lines to show */
  -webkit-box-orient: vertical;
`
