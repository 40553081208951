import { useMutation } from '@apollo/client'

import * as MUTATIONS from './mutations'
import { useGetCompanies, useGetProjects } from '../Cms/hooks'

export function useCloneProject() {
  const [cloneProjectAdmin, { loading, error, data }] = useMutation(
    MUTATIONS.CLONE_PROJECT
  )

  const cloneProject = payload =>
    cloneProjectAdmin({ variables: { payload }, errorPolicy: 'all' })

  return [cloneProject, { loading, error, data }]
}

export const useGetOrganisations = useGetCompanies
export const useFindProjects = useGetProjects
