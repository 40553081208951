import styled from 'styled-components'

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
`

export const Loading = styled.div`
  position: absolute;
  left: 45%;
  top: 30%;
  text-align: center;
`

export const LoaderCard = styled.div`
  border-radius: 8px;
  background: ${props => (props.light ? '#fff' : '#000')};
  opacity: ${props => props.opacity};
  padding: 16px;
`
