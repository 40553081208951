import * as Yup from 'yup'

export const domainSchema = Yup.object({
  organization: Yup.string()
    .trim()
    .required('Organization is required!')
    .typeError('Enter a valid value!'),
  domain: Yup.string()
    .trim()
    .required('Domain is required!')
    .typeError('Enter a valid value!'),
  status: Yup.string()
    .required('Status is required!')
    .typeError('Enter a valid value!'),
})
