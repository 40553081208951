import { useQuery, useLazyQuery, useMutation } from '@apollo/client'

import * as QUERIES from './queries'
import * as MUTATIONS from './mutations'

export function useGetCompanies() {
  const { loading, error, data } = useQuery(QUERIES.GET_COMPANIES)

  return { loading, error, data }
}

export function useGetProjects(filter) {
  const { loading, error, data, fetchMore } = useQuery(QUERIES.GET_PROJECTS, {
    variables: { filter },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  return { loading, error, data, fetchMore }
}

export function useGetAnnualSettlement(filter) {
  const { loading, error, data } = useQuery(QUERIES.GET_ANNUAL_SETTLEMENT, {
    variables: { filter },
  })

  return { loading, error, data }
}

export function useGetUsers() {
  const [getUsersAdmin, { loading, error, data, refetch }] = useLazyQuery(
    QUERIES.GET_USERS
  )

  const getUsers = filter => getUsersAdmin({ variables: { filter } })

  return [getUsers, { loading, error, data, refetch }]
}

export function useUpdateUser(filter) {
  const [updateUserAdmin, { loading, error, data }] = useMutation(
    MUTATIONS.UPDATE_USER,
    {
      refetchQueries: () => {
        return [
          {
            query: QUERIES.GET_USERS,
            variables: { filter },
          },
        ]
      },
    }
  )

  const updateUser = (id, user) => updateUserAdmin({ variables: { id, user } })

  return [updateUser, { loading, error, data }]
}

export function useDeleteUser(filter) {
  const [deleteUserAdmin, { loading, error, data }] = useMutation(
    MUTATIONS.DELETE_USER,
    {
      refetchQueries: () => {
        return [
          {
            query: QUERIES.GET_USERS,
            variables: { filter },
          },
        ]
      },
    }
  )

  const deleteUser = (id, company) =>
    deleteUserAdmin({ variables: { id, company } })

  return [deleteUser, { loading, error, data }]
}

export function useDeleteProject(company) {
  const [deleteProjectAdmin, { loading, error, data }] = useMutation(
    MUTATIONS.DELETE_PROJECT,
    {
      refetchQueries: () => {
        return [
          {
            query: QUERIES.GET_PROJECTS,
            variables: { filter: { company } },
          },
        ]
      },
    }
  )

  const deleteProject = id => deleteProjectAdmin({ variables: { id } })

  return [deleteProject, { loading, error, data }]
}

export function useFindSiteAgreements(filter) {
  return useQuery(QUERIES.FIND_SITE_AGREEMENTS, { variables: { filter } })
}
