import React from 'react'
import GoogleMapReact from 'google-map-react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Text } from '../Themed'

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env

const center = {
  lat: 59.95,
  lng: 30.33,
}

const zoom = 0

function Marker(props) {
  const { name, user, company } = props

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          <div style={{ fontSize: 18 }}>
            <strong>{name}</strong>
          </div>
          {/* <div>{user}</div> */}
          <div>{company}</div>
        </Tooltip>
      }
    >
      <span
        style={{ cursor: 'pointer' }}
        className="material-icons icon-danger"
      >
        location_on
      </span>
    </OverlayTrigger>
  )
}

function OriginationMap(props) {
  const { data } = props

  const sanitizeData = mapData =>
    mapData.filter(
      d => d.project?.site?.location?.[1] && d.project?.site?.location?.[0]
    )

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        {sanitizeData(data).map(d => {
          return (
            <Marker
              key={d._id}
              name={d.project?.name}
              user={`${d.user?.firstName || ''} ${d.user?.lastName || ''}`}
              company={d.company?.name}
              lat={d.project?.site?.location?.[1]}
              lng={d.project?.site?.location?.[0]}
            />
          )
        })}
      </GoogleMapReact>
    </div>
  )
}

export default OriginationMap
