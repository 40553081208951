import React from 'react'
import { Spinner } from 'react-bootstrap'

import * as Styled from './styles'
import { Text } from '../Themed'

function Loader(props) {
  const { type, light, loadingText = 'Loading', opacity = 0.5 } = props
  return (
    <Styled.LoaderContainer>
      <Styled.Loading>
        <Styled.LoaderCard light={light ? 1 : 0} opacity={opacity}>
          <Spinner animation="border" variant={type || 'primary'} />
          <Text color={!light ? '#fff' : ''}>{loadingText}</Text>
        </Styled.LoaderCard>
      </Styled.Loading>
    </Styled.LoaderContainer>
  )
}

export default Loader
