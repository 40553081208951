export const DOMAIN_STATUSES = [
  { value: 'WHITELISTED', label: 'Whitelisted' },
  { value: 'BLACKLISTED', label: 'Blacklisted' },
]

export const BADGE_MAP = {
  WHITELISTED: {
    variant: 'success',
    label: 'Whitelisted',
  },
  BLACKLISTED: {
    variant: 'danger',
    label: 'Blacklisted',
  },
}
