import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {
  Badge,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Spinner,
} from 'react-bootstrap'
import Select from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useApolloClient } from '@apollo/client'

import { TopPanel } from '../Navigation'
import { withAuthorization } from '../Session'
import { CMS_PANEL_TABS } from './constants'
import { Card, CardContent, Text } from '../Themed'
import Loader from '../Loader'
import { DROPDOWN_STYLES } from '../../constants/styles'
import { UserEditForm } from './forms'
import AnnualSettlement from './settlement'
import EditSite from './EditSite'

import * as hooks from './hooks'
import { GET_PROJECT_REPORTS } from './queries'
import { PRIMARY_ALT } from '../../constants/colors'
import { GET_USERS } from './queries'

const { REACT_APP_USER_CLIENT, REACT_APP_REPORTS_URL } = process.env

const ModalBody = styled(Modal.Body)`
  overflow: hidden auto;
  height: calc(100vh - 150px);
`

const ROLE_MAP = {
  HST_ADMIN: 'HST Admin',
  ADMIN: 'Admin',
  MANAGER: 'Manager',
}

function EditProject(props) {
  const { project } = props

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`${REACT_APP_USER_CLIENT}/develop/edit/results/${project}`}
    >
      <span className="cursor-pointer material-icons icon-primary cursor-pointer mr-1">
        edit
      </span>
    </a>
  )
}

function FigureModal(props) {
  const { show, figure, handleClose } = props

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      // dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>{figure?.type}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <img
          src={figure?.url}
          alt="settlement"
          style={{ width: '100%' }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = 'img/noprojects.svg'
          }}
        />
      </ModalBody>
    </Modal>
  )
}

function ReportSummary(props) {
  const { report, currency } = props
  const [show, setShow] = useState(false)
  const [figure, setFigure] = useState(undefined)

  const formattedDate = new Date(report?.createdAt).toLocaleDateString(
    'en-US',
    {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }
  )
  const formattedTime = new Date(report?.createdAt).toLocaleTimeString('en-US')
  const handleClose = () => setShow(false)
  const handleShow = (url, type) => {
    setFigure({ url, type })
    setShow(true)
  }

  const getReportsUrl = row => {
    if (row) {
      const pxxReportPrefix = row.pxx
      const url =
        Number(row.submissionId.split('-')[1]) < 9999
          ? `${REACT_APP_REPORTS_URL}/${row.submissionId}/index.html`
          : pxxReportPrefix
          ? `${REACT_APP_REPORTS_URL}/${row.project}/${row.submissionId}/${pxxReportPrefix}/index.html`
          : `${REACT_APP_REPORTS_URL}/${row.project}/${row.submissionId}/index.html`
      return url
    }

    return ''
  }

  const getAssetsUrl = row => {
    if (row) {
      const pxxReportPrefix = row.pxx
      return Number(row.submissionId.split('-')[1]) < 9999
        ? `${REACT_APP_REPORTS_URL}/${row.submissionId}`
        : pxxReportPrefix
        ? `${REACT_APP_REPORTS_URL}/${row.project}/${row.submissionId}/${pxxReportPrefix}/assets`
        : `${REACT_APP_REPORTS_URL}/${row.project}/${row.submissionId}/assets`
    }

    return ''
  }

  const getImgUrl = row => {
    if (row) {
      const pxxReportPrefix = row.pxx
      return pxxReportPrefix
        ? `${REACT_APP_REPORTS_URL}/${row.project}/${row.submissionId}/${pxxReportPrefix}/img`
        : `${REACT_APP_REPORTS_URL}/${row.project}/${row.submissionId}/img`
    }
    return ''
  }

  return (
    <>
      <FigureModal show={show} handleClose={handleClose} figure={figure} />
      <div>
        <div className="d-flex align-items-center">
          <h3 className="mr-2">{report.name}</h3>
          <a
            href={getReportsUrl(report)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="material-icons" style={{ fontSize: '1.2rem' }}>
              open_in_new
            </span>
          </a>
        </div>
        <p className="text-muted">
          {formattedDate}
          {', '}
          <span className="small">{formattedTime}</span>
        </p>
        <a
          className="btn btn-outline-primary"
          href={`${getAssetsUrl(report)}/energy_revenue.csv`}
          download
        >
          Energy.xlsx
        </a>{' '}
        <Button
          variant="outline-primary"
          onClick={() =>
            handleShow(
              `${getImgUrl(report)}/figure_settlement_breakdown.png`,
              'Settlement Breakdown'
            )
          }
        >
          Settlement Breakdown
        </Button>{' '}
        <Button
          variant="outline-primary"
          onClick={() =>
            handleShow(
              `${getImgUrl(report)}/figure_settlement_histogram.png`,
              'Settlement Histogram'
            )
          }
        >
          Settlement Histogram
        </Button>{' '}
        <AnnualSettlement
          filter={{
            submissionId: report.submissionId,
            projectId: report.project,
            pxx: report.pxx,
          }}
          currency={currency}
        />
      </div>
    </>
  )
}

function ReportSelect(props) {
  const { project, onSelect } = props
  const client = useApolloClient()

  const handleLoadMore = async (search, prevOptions) => {
    const { data } = await client.query({
      query: GET_PROJECT_REPORTS,
      variables: {
        filter: {
          project,
          limit: 10,
          offset: prevOptions.length,
          search,
          internalMessage: 'SUCCESS',
        },
      },
    })

    const reportOptions = data?.reports.map(report => {
      return {
        value: report,
        label: (
          <div className="d-flex align-items-center justify-content-between">
            {report.name}
            <Badge pill variant="primary">
              {report.pxx || 'P50'}
            </Badge>
          </div>
        ),
      }
    })

    return {
      options: reportOptions,
      hasMore: !!reportOptions.length,
    }
  }

  return (
    <AsyncPaginate
      style={DROPDOWN_STYLES}
      className="mr-2"
      debounceTimeout={300}
      loadOptions={handleLoadMore}
      placeholder="Search Reports..."
      onChange={({ value }) => onSelect(value)}
      // styles={{ ...CUSTOM_SELECT_STYLES, ...colourStyles }}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        LoadingIndicator: () => null,
        // LoadingIndicator: () => (
        //   <Spinner variant="primary" size="sm" animation="border" />
        // ),
      }}
    />
  )
}

function DeleteProject(props) {
  const { project, company } = props
  const [show, setShow] = useState(false)

  const [deleteProject, { loading, error, data }] =
    hooks.useDeleteProject(company)

  useEffect(() => {
    if (data?.deleteProject?.success) setShow(false)
  }, [data])

  const handleClose = () => setShow(false)

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>DELETE PROJECT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{' '}
          <b className="text-danger">{project.name}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteProject(project.id)}
            disabled={loading}
          >
            <div className="d-flex align-items-center justify-content-center">
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              Delete
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
      <span
        className="cursor-pointer material-icons icon-danger cursor-pointer"
        onClick={() => setShow(true)}
      >
        delete
      </span>
    </>
  )
}

function EditUser(props) {
  const { user, filter } = props
  const { company } = filter
  const { id, ...rest } = user

  const [show, setShow] = useState(false)
  const [updateUser, { loading, error, data }] = hooks.useUpdateUser(filter)

  useEffect(() => {
    if (data?.updateUserAdmin?.id) setShow(false)
  }, [data])

  const formRef = useRef(null)

  const handleClose = () => setShow(false)

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>EDIT USER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserEditForm
            formRef={formRef}
            onUserSubmit={values => updateUser(user.id, values)}
            values={rest}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              formRef.current.dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true })
              )
            }
            disabled={loading}
          >
            <div className="d-flex align-items-center justify-content-center">
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              Save
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
      <span
        className="cursor-pointer material-icons icon-primary cursor-pointer"
        onClick={() => setShow(true)}
      >
        edit
      </span>
    </>
  )
}

function DeleteUser(props) {
  const { user, filter, query = GET_USERS } = props
  const { company } = filter
  const [show, setShow] = useState(false)

  const [deleteUser, { loading, error, data }] = hooks.useDeleteUser(filter)

  useEffect(() => {
    if (data?.deleteUserAdmin?.success) setShow(false)
  }, [data])

  const handleClose = () => setShow(false)

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>DELETE USER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{' '}
          <b className="text-danger">{`${user.firstName} ${user.lastName}`}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteUser(user.id, company)}
            disabled={loading}
          >
            <div className="d-flex align-items-center justify-content-center">
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              Delete
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
      <span
        className="cursor-pointer material-icons icon-danger cursor-pointer"
        onClick={() => setShow(true)}
      >
        delete
      </span>
    </>
  )
}

export function Users(props) {
  const { filter, editable = true, refetchMe, toggleRefetch } = props
  const { company } = filter
  const [getUsers, { loading, error, data, refetch }] = hooks.useGetUsers()

  useEffect(() => {
    if (company) getUsers(filter)
  }, [company])

  useEffect(() => {
    if (refetchMe) {
      refetch()
      toggleRefetch(false)
    }
  }, [refetchMe])

  if (loading) return <Loader />
  if (error) return <p>error...</p>
  if (!company) return <p>please select company</p>
  if (!data?.users.length) return <p>no users found!</p>

  return (
    <CardContent offset={250}>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th className="text-right"></th>
          </tr>
        </thead>
        <tbody>
          {(data?.users || []).map(user => (
            <tr key={user.id} className="pr-4 pl-4">
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">
                    {user.firstName} {user.lastName}
                  </span>
                  <Text size={12} weight={600} color={PRIMARY_ALT}>
                    {ROLE_MAP[user.role]}
                  </Text>
                </div>
                <span className="text-muted" style={{ fontSize: 12 }}>
                  {user.email}
                </span>
              </td>
              <td className="text-right">
                {editable && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit</Tooltip>}
                  >
                    <EditUser user={user} company={company} filter={filter} />
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Delete</Tooltip>}
                >
                  <DeleteUser user={user} company={company} filter={filter} />
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardContent>
  )
}

function Sites(props) {
  const { company } = props
  const { loading, error, data, refetch } = hooks.useFindSiteAgreements({
    company,
  })

  if (loading) return <Loader />
  if (error) return <p>error...</p>
  if (!company) return <p>please select company</p>
  if (!data?.siteAgreements.length) return <p>no reserved sites found!</p>

  return (
    <CardContent offset={250}>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {(data?.siteAgreements || []).map(siteAgreement => (
            <tr key={siteAgreement.site.id} className="pr-4 pl-4">
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{siteAgreement.site.name}</span>
                  {siteAgreement.site.type && (
                    <Badge pill variant="primary">
                      {siteAgreement.site.type}
                    </Badge>
                  )}
                </div>
                <span className="text-muted" style={{ fontSize: 12 }}>
                  Reserved On:{' '}
                  {moment(siteAgreement.createdAt).format('MM/DD/YYYY')}{' '}
                  {siteAgreement.siteMetadata.isInFirstLookExpiryPeriod && (
                    <>
                      | Expires On:{' '}
                      {moment(
                        siteAgreement.siteMetadata.exclusivityExpiry
                      ).format('MM/DD/YYYY')}{' '}
                      |
                      <EditSite
                        siteAgreement={siteAgreement}
                        refetch={refetch}
                      />
                    </>
                  )}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardContent>
  )
}

function ProjectRow(props) {
  const { project, company } = props
  const [report, setReport] = useState(undefined)

  return (
    <Row className="p-3" style={{ borderBottom: '1px solid #dee2e6' }}>
      <Col xs={7}>
        <b>{project.name}</b>
      </Col>
      <Col xs={5} className="d-flex align-items-center justify-content-end">
        <div className="w-100">
          <ReportSelect project={project.id} onSelect={setReport} />
        </div>
        <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
          <EditProject project={project.id} />
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
          <DeleteProject project={project} company={company} />
        </OverlayTrigger>
      </Col>
      <Col xs={12}>
        {report && (
          <ReportSummary report={report} currency={project.currency} />
        )}
      </Col>
    </Row>
  )
}

function Projects(props) {
  const { company } = props

  const filter = {
    company,
    limit: 40,
    offset: 0,
  }

  const { loading, error, data, fetchMore } = hooks.useGetProjects(filter)

  const handleScroll = ({ currentTarget }) => {
    if (
      currentTarget.scrollHeight -
        currentTarget.clientHeight -
        currentTarget.scrollTop <
      1
    ) {
      fetchMore({
        variables: {
          filter: {
            ...filter,
            offset: data?.projects.length,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult.projects.length) {
            return prev
          }
          return Object.assign({}, prev, {
            projects: [...prev.projects, ...fetchMoreResult.projects],
          })
        },
      })
    }
  }

  // if (loading) return <Loader />
  if (error) return <p>error...</p>
  if (!company) return <p>please select company</p>
  if (!loading && !data?.projects.length) return <p>no projects found!</p>

  return (
    <>
      <CardContent offset={250} onScroll={handleScroll}>
        <Row
          className="pl-2 pt-2 pb-2"
          style={{ borderBottom: '2px solid #dee2e6' }}
        >
          <Col xs={7}>Name</Col>
          <Col xs={5}>Actions</Col>
        </Row>
        {(data?.projects || []).map(project => (
          <ProjectRow key={project.id} project={project} company={company} />
        ))}
      </CardContent>
      {loading && <Loader />}
    </>
  )
}

function Cms() {
  const [tab, setTab] = useState('users')
  const [companyId, setCompanyId] = useState(undefined)

  const { loading, error, data } = hooks.useGetCompanies()

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { companies } = data

  return (
    <Card>
      <Row>
        <Col xs={8}>
          <TopPanel
            tabs={CMS_PANEL_TABS}
            onTabPress={val => setTab(val)}
            defaultValue="users"
          />
        </Col>
        <Col xs={4}>
          <Select
            className="mr-2 mt-2"
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            styles={DROPDOWN_STYLES}
            options={companies.map(company => ({
              label: company.name,
              value: company.id,
            }))}
            onChange={({ value }) => setCompanyId(value)}
          />
        </Col>
      </Row>
      {Object.is(tab, 'users') && (
        <Users filter={{ company: companyId, role: { $ne: 'HST_ADMIN' } }} />
      )}
      {Object.is(tab, 'projects') && <Projects company={companyId} />}
      {Object.is(tab, 'sites') && <Sites company={companyId} />}
    </Card>
  )
}

export default withAuthorization(session => session && session.me)(Cms)
