import { useQuery } from '@apollo/client'

import * as QUERIES from './queries'
import { useGetCompanies } from '../Cms/hooks'

export function useGetCountryMetrics() {
  const { loading, error, data } = useQuery(QUERIES.GET_COUNTRY_METRICS)

  return { loading, error, data }
}

export function useGetProjectsAnalytics(start, end) {
  const { loading, error, data } = useQuery(QUERIES.GET_PROJECTS_ANALYTICS, {
    variables: { start, end },
  })

  return { loading, error, data }
}

export function useGetCompanyAnalytics(company) {
  const { loading, error, data } = useQuery(QUERIES.GET_COMPANY_ANALYTICS, {
    variables: { company }
  })

  return { loading, error, data }
}

export const useGetAllCompanies = useGetCompanies
