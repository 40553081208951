import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { setDefaultOptions } from 'esri-loader'

import SignIn from '../Login'
import Landing from '../Landing'
import Cms from '../Cms'
import Managers from '../Managers'
import Equipments from '../Equipments'
import Usage from '../Usage'
import Originations from '../Originations'
import MarketData from '../MarketDataServices'
import EmailDomains from '../EmailDomains'
import Projects from '../Projects'
import AnalyticsDataSource from '../Data'
import Companies from '../Companies'
import Signup from '../Signup'
import BrandCircle from '../Circle'
import Header from '../Header'
import SidePanel from '../Navigation'
import Sites from '../Sites'

import Loader from '../Loader'
import { GlobalStyle } from '../Themed'

import withSession from '../Session'

import * as ROUTES from '../../constants/routes'
import { NAV_ITEMS } from '../Navigation/constants'

// configure esri-loader to lazy load the CSS
// the first time any react-arcgis components are rendered
setDefaultOptions({ css: true })

function App(props) {
  const { session, loading } = props

  if (loading) return <Loader />

  return (
    <Router>
      <GlobalStyle />
      <BrandCircle position="left" />
      <BrandCircle position="right" />
      <Route exact path={ROUTES.SIGN_IN} component={() => <SignIn />} />
      <div className="h-100">
        <Header session={session} />
        <div className="d-flex h-100 p-4">
          <SidePanel items={NAV_ITEMS} session={session} />
          <div className="col">
            <Route exact path={ROUTES.LANDING} component={() => <Landing />} />
            <Route exact path={ROUTES.SITE_UPLOAD} component={Sites} />
            <Route
              exact
              path={ROUTES.ORIGINATIONS}
              component={() => <Originations />}
            />
            <Route
              exact
              path={ROUTES.MARKET_DATA_SERVICES}
              component={() => <MarketData />}
            />

            <Route exact path={ROUTES.CMS} component={() => <Cms />} />
            <Route
              exact
              path={ROUTES.MANAGERS}
              component={() => <Managers />}
            />
            <Route
              exact
              path={ROUTES.EQUIPMENTS}
              component={() => <Equipments />}
            />
            <Route
              exact
              path={ROUTES.PROJECTS}
              component={() => <Projects />}
            />
            <Route
              exact
              path={ROUTES.ANALYTICS_SOURCE}
              component={() => <AnalyticsDataSource />}
            />
            <Route
              exact
              path={ROUTES.COMPANIES}
              component={() => <Companies />}
            />
            <Route exact path={ROUTES.USAGE} component={() => <Usage />} />
            <Route exact path={ROUTES.SIGN_UP} component={() => <Signup />} />
            <Route
              exact
              path={ROUTES.EMAIL_DOMAINS}
              component={() => <EmailDomains />}
            />
          </div>
        </div>
      </div>
    </Router>
  )
}

export default withSession(App)
