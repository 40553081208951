import { WHITE } from '../../../../constants/colors'
import styled from 'styled-components'

export const FloatingContainer = styled.div`
  position: absolute;
  bottom: ${props => (props.bottom ? props.bottom.toString() + 'px' : '')};
  left: ${props => (props.left ? props.left.toString() + 'px' : '')};
  right: ${props => (props.right ? props.right.toString() + 'px' : '')};
  top: ${props => (props.top ? props.top.toString() + 'px' : '')};
  border-radius: 8px;
  background: ${WHITE};
  opacity: 0.9;
  z-index: 300;
`
