import { gql } from '@apollo/client'

export const GET_COUNTRY_METRICS = gql`
  query {
    countryMetrics: countryMetricsAdmin
  }
`

export const GET_PROJECTS_ANALYTICS = gql`
  query projectsAnalytics($start: Date!, $end: Date!) {
    projectsAnalytics: getProjectsAnalytics(start: $start, end: $end)
  }
`

export const GET_COMPANY_ANALYTICS = gql`
  query companyAnalytics($company: ID) {
    projectsAnalyticsByCompany: getProjectsAnalyticsByCompany(company: $company)
  }
`
