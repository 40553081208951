import { Card } from '../Themed'
import AddDomain from './AddDomain'
import { Title } from '../Themed'
import Domains from './Domains'

function EmailDomains(props) {
  return (
    <Card>
      <div className="d-flex justify-content-between mb-2 p-3 border-bottom">
        <Title>Email Domains</Title>
        <AddDomain />
      </div>
      <Domains />
    </Card>
  )
}

export default EmailDomains
