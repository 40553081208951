import { useMutation } from '@apollo/client'
import { UPDATE_SITE_AGREEMENT } from './mutations'

export function useUpdateSiteAgreement(onCompleted) {
  const [updateSA, { loading, error, data }] = useMutation(
    UPDATE_SITE_AGREEMENT,
    { onCompleted }
  )

  const updateSiteAgreement = (id, agreement) =>
    updateSA({ variables: { id, agreement } })

  return [updateSiteAgreement, { loading, error, data }]
}
