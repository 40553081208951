import { gql } from '@apollo/client'

export const REGISTER_PROJECT_MANAGER = gql`
  mutation registerProjectManager($manager: UserInput!) {
    saveProjectManager: registerProjectManager(manager: $manager) {
      success
      message
    }
  }
`
