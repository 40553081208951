import { gql } from '@apollo/client'

export const GET_USAGE_STATS = gql`
  query getUsageStates($start: Date!, $end: Date!, $company: ID) {
    activeUsers: getActiveUsers(start: $start, end: $end, company: $company)
    activeProjects: getActiveProjects(
      start: $start
      end: $end
      company: $company
    )
    newProjects: getNewProjects(start: $start, end: $end, company: $company)
    submissions: getSubmissions(start: $start, end: $end, company: $company)
    activeGWCapacity: getActiveGWCapacityModeled(
      start: $start
      end: $end
      company: $company
    )
  }
`

export const GET_USAGE = gql`
  query getUsage($start: Date!, $end: Date!, $company: ID) {
    usage(start: $start, end: $end, company: $company)
  }
`
