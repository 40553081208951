import { useState } from 'react'
import { Button, OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import { DANGER, WHITE } from '../../constants/colors'
import { useDeleteEmailDomain } from './hooks'

function DeleteDomain(props) {
  const { domain } = props
  const [show, setShow] = useState(false)
  const [deleteDomain, { loading }] = useDeleteEmailDomain()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  //Felt cute. Might delete later.
  const handleDomainDelete = (
    (domainId, deleteDomainFn) => () =>
      deleteDomainFn(domainId)
  )(domain.id, deleteDomain)

  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      overlay={
        <Popover>
          <Popover.Title as="h3" style={{ background: DANGER, color: WHITE }}>
            Delete Email Domain
          </Popover.Title>
          <Popover.Content className="pb-0">
            Are you sure you want to delete <b>@{domain.domain}</b> domain?
          </Popover.Content>
          <div className="float-right p-2 d-flex">
            <Button
              variant="secondary"
              size="sm"
              className="mr-2"
              onClick={handleClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              size="sm"
              disabled={loading}
              onClick={handleDomainDelete}
              className="d-flex align-items-center justify-content-center"
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                  style={{ height: 12, width: 12 }}
                />
              )}
              <span>Delete</span>
            </Button>
          </div>
        </Popover>
      }
      show={show}
      onToggle={handleShow}
    >
      <span role="button" className="material-icons icon-danger icon-16 ml-2">
        delete
      </span>
    </OverlayTrigger>
  )
}

export default DeleteDomain
