import { ToggleButton } from 'react-bootstrap'
import styled from 'styled-components'

export const SwitchButton = styled(ToggleButton)`
  z-index: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  &:focus {
    z-index: 0 !important;
    outline: 0px !important;
    box-shadow: none !important;
  }
`
