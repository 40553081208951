import { useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'

const { REACT_APP_DEVELOP_MARKETPLACE_SITING_CF_DOMAIN } = process.env

function SiteAgreements(props) {
  const { className = '', site } = props
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Button
        variant="outline-primary"
        size="sm"
        className={className}
        onClick={handleShow}
      >
        Agreements
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Submitted Site Control Agreements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Author</th>
                <th>Company</th>
                <th>Type</th>
                <th>Status</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {site.agreements.map((agreement, i) => (
                <tr key={agreement.id}>
                  <td>{i + 1}</td>
                  <td>{`${agreement?.user?.firstName} ${agreement?.user?.lastName}`}</td>
                  <td>{agreement?.company?.name}</td>
                  <td>{agreement.type}</td>
                  <td>{agreement.status}</td>
                  <td>
                    <a
                      href={`${REACT_APP_DEVELOP_MARKETPLACE_SITING_CF_DOMAIN}/${agreement?.agreementURL}`}
                      download
                    >
                      Agreement
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SiteAgreements
