import React from 'react'

function BrandCircle(props) {
  const { position } = props

  const circleStyle = { position: 'absolute', zIndex: -1 }

  const leftCircleStyle = { ...circleStyle, bottom: 200, left: 10 }
  const rightCircleStyle = { ...circleStyle, top: 76, right: 0 }

  return (
    <div style={position === 'left' ? leftCircleStyle : rightCircleStyle}>
      <svg>
        <circle
          cx="80"
          cy="80"
          r="66"
          fill="transparent"
          strokeWidth="2"
          stroke="white"
          strokeDasharray="4,4"
        />
        <circle
          cx="80"
          cy="80"
          r="58"
          fill="transparent"
          strokeWidth="2"
          stroke="white"
          strokeDasharray="2,2"
        />
      </svg>
    </div>
  )
}

export default BrandCircle
