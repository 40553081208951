import React, { useState } from 'react'
import numeral from 'numeral'
import { Chart } from 'react-google-charts'
import { Bar } from 'react-chartjs-2'
import styled from 'styled-components'
import { Button, Modal, Table } from 'react-bootstrap'
import { ServerError } from '../Error'
import { useGetAnnualSettlement } from './hooks'
import Loader from '../Loader'
import Switcher from '../Switcher'
import { PRIMARY, WHITE } from '../../constants/colors'

export const SETTLEMENT_SWITCH_ITEMS = [
  {
    label: <span>Table</span>,
    value: 'table',
  },
  {
    label: <span>Graph</span>,
    value: 'graph',
  },
]

const SettlementContainer = styled.div`
  overflow: hidden auto;
  height: calc(100vh - 180px);
  padding: 1rem;
`

function SettlementModal(props) {
  const { filter, show, handleClose, currency } = props
  const [settlementDisplayType, setSettlementDisplayType] = useState('table')

  const { loading, error, data } = useGetAnnualSettlement(filter)

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Annual Settlement</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="text-center mt-2">
          <Switcher
            switchItems={SETTLEMENT_SWITCH_ITEMS}
            defaultValue={settlementDisplayType}
            onSwitch={value => setSettlementDisplayType(value)}
          />
        </div>
        <SettlementContainer>
          <Settlement
            loading={loading}
            error={error}
            data={data}
            type={settlementDisplayType}
            currency={currency}
          />
        </SettlementContainer>
      </Modal.Body>
    </Modal>
  )
}

function Settlement(props) {
  const { loading, error, data, type, currency } = props

  if (error)
    return <ServerError subtitle="Annual Settlement does not exist!" />
  if (loading) return <Loader />

  const { settlement } = data
  const annualSettlement = settlement?.[0]

  return (
    <>
      {type === 'table' ? (
        <SettlementTable
          currency={currency === 'USD' ? '$' : currency}
          annualSettlement={annualSettlement}
        />
      ) : (
        <SettlementGraph
          annualSettlement={annualSettlement}
          currency={currency}
        />
      )}
    </>
  )
}

function SettlementTable(props) {
  const { annualSettlement, currency } = props

  const totalSettlement = (Object.values(annualSettlement || {}) || []).reduce(
    (acc, s) => acc + parseFloat(s),
    0
  )

  return (
    <>
      <strong>
        Total: {currency === '$' ? currency : `${currency} `}
        {numeral(totalSettlement).format('0,0.[00]')}
      </strong>
      <Table striped bordered hover size="sm">
        <thead
          style={{
            background: PRIMARY,
            color: WHITE,
          }}
        >
          <tr>
            <th>YEAR</th>
            <th>SETTLEMENT ({currency})</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(annualSettlement).map(item => (
            <tr key={item}>
              <td>
                <b>{item}</b>
              </td>
              <td>
                <span className={item === 'Total' ? 'font-weight-bold' : ''}>
                  {numeral(annualSettlement[item]).format('0,0.[00]')}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

function SettlementGraph(props) {
  const { annualSettlement, currency } = props
  const settlementDataTable = Object.keys(annualSettlement).map(item => [
    item,
    parseFloat(annualSettlement[item]),
  ])

  const dataTable = [['Year', 'Settlement'], ...settlementDataTable]

  return (
    <Bar
      data={{
        labels: Object.keys(annualSettlement),
        datasets: [
          {
            label: 'Settlement',
            data: Object.values(annualSettlement),
            backgroundColor: '#4284F3',
            borderColor: '#4284F3',
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || ''

                if (label) {
                  label += ': '
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currency,
                  }).format(context.parsed.y)
                }
                return label
              },
            },
          },
        },
      }}
    />
  )
}

function AnnualSettlement(props) {
  const { filter, currency } = props
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow}>
        Annual Settlement
      </Button>
      <SettlementModal
        filter={filter}
        show={show}
        handleClose={handleClose}
        currency={currency}
      />
    </>
  )
}

export default AnnualSettlement
