import { gql } from '@apollo/client'

export const GET_COMPANIES = gql`
  query {
    companies {
      id
      name
      settings {
        maxProjectCount
        maxUserCount
        maxOptimizationCount
        layersEnabled
        ultra8760Enabled
        marketplacePreviewEnabled
        merchantCurveEnabled
        enabledTabs
        pxxEnabled
        pushToSitesEnabled
        sitesInteractiveMapToolsEnabled
        srcEnabled
        solcastEnabled
        solcastDownloadEnabled
        cashflowEnabled
        additionalExclusionsEnabled
        license
        licenseStart
        licenseEnd
        dealAnalyticsTrialEnd
        srcApiKey
        solcastApiKey
        srcLicense
        srcLicenseEnd
        sitingClass
        sensitivityCalcEnabled
      }
      legal {
        msa
      }
    }
  }
`

export const GET_PROJECTS = gql`
  query getProjects($filter: JSON!) {
    projects(filter: $filter) {
      id
      name
      streetAddress
      currency
    }
  }
`

export const GET_USERS = gql`
  query getUsers($filter: JSON!) {
    users(filter: $filter) {
      id
      firstName
      lastName
      email
      active
      role
    }
  }
`
export const GET_PROJECT_REPORTS = gql`
  query getProjectReports($filter: JSON!) {
    reports: getProjectReports(filter: $filter) {
      id
      name
      pxx
      electrical {
        acSystemSizeInKw
        firstYearProductionInKwh
      }
      financial {
        lcoeReal
        ppaPrice
      }
      project
      submissionId
      assets {
        incCoreSensPlots
        incAddSensPlots
        incBom
        incFinancialModel
        incDwg
        incShading
        incKml
        incEnergyRevenue
        incLog
      }
      createdAt
      ultra8760Enabled
      merchantCurveEnabled
    }
  }
`

export const GET_ANNUAL_SETTLEMENT = gql`
  query getAnnualSettlement($filter: JSON!) {
    settlement: getAnnualSettlement(filter: $filter)
  }
`

export const FIND_SITE_AGREEMENTS = gql`
  query FIND_SITE_AGREEMENTS($filter: JSON!) {
    siteAgreements: findAgreements(filter: $filter) {
      id
      site {
        name
      }
      siteMetadata {
        isInFirstLookExpiryPeriod
        exclusivityExpiry
      }
      createdAt
    }
  }
`
