export const DATA_SOURCE_SWITCH_ITEMS = [
  {
    label: 'US STATES',
    value: 'us',
  },
  {
    label: 'REST OF WORLD',
    value: 'international',
  },
]
