import { useState } from 'react'

const { REACT_APP_BACKEND_SERVER } = process.env

export const useFetchForwardCurve = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const fetchFwdCurve = async (clearingCode, frequency) => {
    const url = `${REACT_APP_BACKEND_SERVER}/api/mds/fwd-curve?frequency=${frequency}&clearingCode=${clearingCode}`
    setLoading(true)

    try {
      const blob = await new Promise((resolve, reject) => {
        fetch(url)
          .then(async response => {
            if (!response.ok) {
              const isJson = response.headers
                .get('content-type')
                ?.includes('application/json')
              const data = isJson ? await response.json() : null
              const error = (data && data.message) || response.status
              setError(error)
              reject(error)
            } else {
              resolve(response.blob())
            }
          })
          .catch(err => reject(err))
      })

      const file = window.URL.createObjectURL(blob)
      window.location.assign(file)

      setLoading(false)
    } catch (e) {
      setError(e.message)
      setLoading(false)
    }
  }

  return [fetchFwdCurve, { loading, error }]
}
