import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Spinner, Row, Col, Button } from 'react-bootstrap'

import Loader from '../Loader'
import ToastNotification from '../Notification'
import { Card, Title, Text } from '../Themed'
import { DROPDOWN_STYLES } from '../../constants/styles'

import { withAuthorization } from '../Session'
import * as hooks from './hooks'

function EquipmentSelect(props) {
  const { company, destination } = props
  const [equipments, setEquipments] = useState({})
  const [show, toggleToast] = useState(false)
  const [findEquipments, { loading, error, data }] = hooks.useFindEquipments()
  const [
    cloneEquipments,
    { loading: cloning, data: cloneData },
  ] = hooks.useCloneEquipments()

  useEffect(() => {
    if (company) findEquipments({ company })
  }, [company])

  useEffect(() => {
    if (cloneData?.cloneEquipments?.success) toggleToast(true)
  }, [cloneData])

  const onEquipmentChange = (type, id) =>
    setEquipments({ ...equipments, [type]: id })

  if (loading) return <p>loading equipments...</p>
  if (error) return <p>error...</p>
  if (!company) return <p>Select organisation...</p>

  return (
    <>
      <ToastNotification
        show={show}
        onSuspend={value => toggleToast(false)}
        headerText="Equipments cloned Successfully!"
        bodyText="Your changes have been saved successfully."
        type="primary"
      />
      <>
        {data &&
          Object.keys(data).map(key => (
            <div className="mb-4" key={key}>
              <Text>{key.toUpperCase()}</Text>
              <Select
                className="mr-2 mt-2"
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                styles={DROPDOWN_STYLES}
                options={data[`${key}`]?.map(eq => ({
                  label: eq.name,
                  value: eq.id,
                }))}
                onChange={({ value }) => onEquipmentChange(key, value)}
              />
            </div>
          ))}
      </>
      <Button
        variant="primary"
        disabled={
          !(Object.keys(equipments).length > 0 && destination) || cloning
        }
        onClick={() => cloneEquipments({ equipments, destination })}
      >
        <div className="d-flex align-items-center justify-content-center">
          {cloning && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"
            />
          )}
          <span>{cloning ? 'Cloning' : 'Clone'}</span>
        </div>
      </Button>
    </>
  )
}

function Equipments(props) {
  const [organisation, setOrganisation] = useState(undefined)
  const [destination, setDestination] = useState(undefined)
  const { loading, error, data } = hooks.useGetOrgranisations()

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { companies } = data

  return (
    <Card padding={32}>
      <Row>
        <Col xs={6}>
          <Title>CLONE EQUIPMENT FROM:</Title>
          <div className="mb-4">
            <Text>ORGANISATION</Text>
            <Select
              className="mr-2 mt-2"
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              styles={DROPDOWN_STYLES}
              options={companies.map(company => ({
                value: company.id,
                label: company.name,
              }))}
              onChange={({ value }) => setOrganisation(value)}
            />
          </div>
          <EquipmentSelect company={organisation} destination={destination} />
        </Col>
        <Col xs={6}>
          <Title>CLONE EQUIPMENT TO:</Title>
          <div className="mb-4">
            <Text>ORGANISATION</Text>
            <Select
              className="mr-2 mt-2"
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              styles={DROPDOWN_STYLES}
              options={companies.map(company => ({
                value: company.id,
                label: company.name,
              }))}
              onChange={({ value }) => setDestination(value)}
            />
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default withAuthorization(session => session && session.me)(Equipments)
