export const CMS_PANEL_TABS = {
  users: {
    label: 'Users',
    icon: 'people',
  },
  projects: {
    label: 'Projects',
    icon: 'flash_on',
  },
  sites: {
    label: 'Sites',
    icon: 'satellite',
  },
}

export const ROLES = [
  { value: 'ADMIN', label: 'Company Admin' },
  { value: 'MANAGER', label: 'Project Manager' },
]
