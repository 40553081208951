import { gql } from '@apollo/client'

export const CREATE_EMAIL_DOMAIN = gql`
  mutation createEmailDomain($domain: DomainInput!) {
    createDomain(domain: $domain) {
      id
    }
  }
`

export const UPDATE_EMAIL_DOMAIN = gql`
  mutation updateEmailDomain($id: ID!, $domain: DomainInput!) {
    updateDomain(id: $id, domain: $domain) {
      id
    }
  }
`

export const DELETE_EMAIL_DOMAIN = gql`
  mutation deleteEmailDomain($id: ID!) {
    deleteDomain(id: $id) {
      id
    }
  }
`

export const CREATE_CONFIGURATION = gql`
  mutation createConfiguration($configuration: ConfigurationInput!) {
    createConfiguration(configuration: $configuration) {
      id
    }
  }
`

export const UPDATE_CONFIGURATION = gql`
  mutation updateConfiguration($id: ID!, $configuration: ConfigurationInput!) {
    updateConfiguration(id: $id, configuration: $configuration) {
      id
    }
  }
`

export const DELETE_CONFIGURATION = gql`
  mutation deleteConfiguration($id: ID!) {
    deleteConfiguration(id: $id) {
      id
    }
  }
`
