import styled from 'styled-components'

import * as COLORS from '../../constants/colors'

export const AppBar = styled.div`
  width: 100%;
  background: ${COLORS.WHITE};
  display: flex;
  align-items: center;
  height: 85px;
`

export const BrandLogo = styled.div`
  margin-right: 8px;
  margin-left: 8px;
  width: 70px;
  cursor: pointer;
  padding-top: 8px;
  text-align: center;
  color: ${COLORS.GREY};
  &:hover {
    color: ${COLORS.PRIMARY};
  }
`

export const Parent = styled.div`
  font-size: 9px;
`

export const Logo = styled.img`
  width: 54px;
  display: block;
  margin: 0 auto;
`

export const AbsoluteWrapper = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background: #212529;
  opacity: 0.5;
`

export const FormGroupContainer = styled.div`
  margin-bottom: 32px;
`
