import React from 'react'
import { Toast, Button } from 'react-bootstrap'

import * as COLORS from '../../constants/colors'
import { AbsoluteWrapper } from '../Themed'

const TOAST_ACCENT = {
  danger: COLORS.DANGER,
  success: COLORS.SUCCESS,
  primary: COLORS.PRIMARY,
}

function ToastNotification(props) {
  const { className, show, onSuspend, headerText, bodyText, type, delay } =
    props

  return (
    <>
      {show && <AbsoluteWrapper />}
      <div
        style={{
          position: 'fixed',
          top: '32px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 2,
        }}
      >
        <Toast
          show={show}
          delay={delay}
          autohide={delay ? true : false}
          className={className}
          style={{
            display: 'block',
            overflow: 'auto',
          }}
        >
          <Toast.Header
            closeButton={false}
            style={{
              background: type ? TOAST_ACCENT[`${type}`] : COLORS.PRIMARY,
            }}
          >
            <strong className="mr-auto" style={{ color: '#fff' }}>
              {headerText}
            </strong>
          </Toast.Header>
          <Toast.Body>
            <div>{bodyText}</div>
            <Button
              className="d-block float-right m-2"
              variant="outline-primary"
              onClick={() => onSuspend()}
              size="sm"
            >
              Done
            </Button>
          </Toast.Body>
        </Toast>
      </div>
    </>
  )
}

export default ToastNotification
