import { useMutation } from '@apollo/client'
import { PUBLISH_UNPUBLISH_SITE } from './mutations'

export function usePublishSite(onCompleted) {
  const [pubSite, { loading, error, data }] = useMutation(
    PUBLISH_UNPUBLISH_SITE,
    { onCompleted }
  )
  const publishSite = (id, publish) => pubSite({ variables: { id, publish } })

  return [publishSite, { loading, error, data }]
}
